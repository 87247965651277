.proje {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
.proje div {
  background-color: gray;
  width: 100%;
  margin-left: 20px;
  color: #fff;
  height: 100px;
}
.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 100px;
}
.list li {
  background-color: darkblue;
  width: 90%;
  gap: 10px;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
}
.list li.active{
    background-color: green;
}
