@import "../../golabl.scss";

.intro {
  background-color: white;
  display: flex;

  .left {
    flex: 0.5;
    overflow: hidden;

    .imgContainer {
      width: 56vw;
      height: 56vw;
      background-color: $lightgreen;
      border-radius: 50%;
      margin-top: 15px;
      justify-content: center;
      display: flex;
      align-items: flex-end;
      float: right;

      img {
        height: 100%;
      }
    }
  }

  .right {
    flex: 0.5;
    overflow: hidden;
    position: relative;

    .wrapper {
      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        font-size: 60px;
        margin: 10px 0;
      }

      h2 {
        font-size: 25px;
      }

      .cv{
        color: $maincolor;
        background-color: $grey ;
        padding: 8px;
        border-radius: 4px;
        position: absolute;
        height: fit-content;
        bottom: 35%;
        text-decoration: none;
        opacity: 0.6;
        transition: 1s;
        &:hover{
          opacity: 1;
        }
      }


      h3 {
        font-size: 30px;

        span{
            color: $maincolor;
        }

        .ityped-cursor{
            animation: blink 1s infinite;
        }

        @keyframes blink {
            50%{
                opacity: 1;
            }
            100%{
                opacity: 0;
            }
        }
      }
    }

    a {
      position: absolute;
      bottom: 10px;

      img {
        width: 30px;
        animation: arrowBlink 2s infinite;
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}
