@import "../../golabl.scss";

.menu {
  width: 250px;
  height: 100vh;
  background-color: $dark;
  position: fixed;
  top: 0;
  right: -250px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;

  &.active {
    right: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 30px;
    font-weight: 300;
    width: 60%;

    li {
      margin-bottom: 25px;
      a {
        text-decoration: none;
        color: $maincolor;
        font-size: inherit;
      }

      &:hover {
        font-weight: 500;
      }
    }
  }
}
