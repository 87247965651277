@import "../../golabl.scss";

/*.portfolio{
    background-color: #ECEEFF;
}*/

.portfolio{
   background-color: white;
    position: relative;
    h1{
        margin-top: 10px;
        margin-bottom: 5px;
        color: $maincolor;
        justify-content: center;
        text-align: center;
    }

    span{
        height: 2px;
        border-radius:1px;
        background-color: $lightgreen;
        width: 30%;
        margin-bottom: 10px;
        margin-left: 35%;
        display: block;
    }
}

.list{
    padding: 0;
    margin-right: 10px;
    align-items: center;
    width: 40%;

    .list-pil{
        padding: 5px 10px;
        margin-right: 10px;
        align-items: center;
        width: fit-content;
        border-radius: 10px;
    }

}