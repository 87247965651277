$maincolor: #01d163;

$lightgreen: #acf397;

$monogreen:#c0ffeb;

$lightergreen: #e8fae0;

$dark:#292929;

$grey:#525252;