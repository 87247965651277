@import "../../golabl.scss";

.topbar {
  background-color: $dark;
  color: white;
  width: 100%;
  height: 70px; //60 is beter fix dit
  position: fixed;
  top: 0;
  z-index: 2;
  overflow: hidden;
  .wrapper {
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

   .left{
     display: flex;
     align-items: center;
     .logo {
      //   width: calc(100vw / 7);
      height: 60px;
      margin-right: 40px;
    }
   }

   .mid{
     display: flex;
     align-items: center;
     margin-left: 30px;
     
     .icon{
       font-size: 18px;
       margin-right: 5px;
     }
     span{
       font-size: 15px;
       font-weight: 400;
     }
   }

    .right {
      .hamburger {
        padding-left: 2px;
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        span {
          // width: 100%;
          height: 3px;
          background-color: $maincolor;
          transform-origin: left;
          transition: all 1s ease;
          right: 0%;

          &:first-child {
            width: 80%;
          }
          &:nth-child(2) {
            width: 100%;
          }
          &:last-child {
            width: 65%;
          }
        }
      }
      .hamburger:hover {
        span {
          width: 100%;
        }
      }
    }
  }

  &.active {
    // background-color: $maincolor;
    color: white;

    .hamburger {
      padding-left: 2px;
      span {
        width: 100%;
        &:first-child {
          background-color: white;
          transform: rotate(45deg);
          border-radius: 1px;
          width: 100% !important;
        }
        &:nth-child(2) {
          transition: all 0.3s ease;
          position: absolute;
          right: -100%;
          top: 50%;
          background-color: white;
        }
        &:last-child {
          background-color: white;
          transform: rotate(-45deg);
          border-radius: 1px;
          width: 100% !important;
        }
      }
    }
  }
  &.active{
    .line1{
      width: 100%;
    }
  }
}
